import { BASE_URL } from "../../constants";
import {
  TAutocorr,
  TBlacklist,
  TCreateAutocorr,
  TCreateBlacklist,
  TEditAutocorr,
} from "../../types/tetris";
import api from "../api-instance";
import { ENDPOINT_AUTOCORR, ENDPOINT_BLACKLIST } from "../endpoints";
import * as T from "../types";

export default class tetrisApi {
  // Автокор
  static async getTetrisAutocorr(): T.PromiseAutocors {
    return api.get(`${BASE_URL}/${ENDPOINT_AUTOCORR}/`);
  }
  static async createTetrisAutocorr(queryParams: TCreateAutocorr): T.PromiseAutocorr {
    return api.post(`${BASE_URL}/${ENDPOINT_AUTOCORR}/`, queryParams);
  }
  static async updateTetrisAutocorr(queryParams: TEditAutocorr[]): T.PromiseAutocors {
    return api.patch(`${BASE_URL}/${ENDPOINT_AUTOCORR}/`, queryParams);
  }
  static async deleteTetrisAutocorr(payload: TAutocorr[]): T.PromiseAutocors {
    return api.delete(`${BASE_URL}/${ENDPOINT_AUTOCORR}/`, { data: payload });
  }

  //Блэклисты
  static async getTetrisBlacklist() {
    return api.get(`${BASE_URL}/${ENDPOINT_BLACKLIST}/`);
  }
  static async createTetrisBlacklist(payload: TCreateBlacklist) {
    return api.post(`${BASE_URL}/${ENDPOINT_BLACKLIST}/`, payload);
  }
  static async deleteTetrisBlacklist(payload: TBlacklist[]) {
    return api.delete(`${BASE_URL}/${ENDPOINT_BLACKLIST}/`, { data: payload });
  }

  //   // Кластеры
  //   static async getTetrisCluster() {
  //     return api.get(`${BASE_URL}/cluster/all`);
  //   }
  //   static async createTetrisCluster(payload) {
  //     return api.post(`${BASE_URL}/cluster/percent`, payload);
  //   }
  //   static async deleteTetrisCluster(payload) {
  //     return api.post(`${BASE_URL}/cluster/delete`, payload);
  //   }
  //   static async updateTetrisCluster(payload) {
  //     return api.post(`${BASE_URL}/cluster/lock`, payload);
  //   }
  //   // Расписание
  //   static async getTetrisSchedule() {
  //     return api.get(`${BASE_URL}/cluster-manager/schedule/`);
  //   }
  //   static async createTetrisSchedule(payload) {
  //     return api.post(`${BASE_URL}/cluster-manager/schedule/old`, payload);
  //   }
  //   static async deleteTetrisSchedule(payload) {
  //     return api.post(`${BASE_URL}/cluster-manager/schedule/delete`, payload);
  //   }
  //   static async updateTetrisSchedule(payload) {
  //     return api.post(`${BASE_URL}/cluster-manager/schedule/lock`, payload);
  //   }
  //   static async updateTetrisScheduleInfinity(payload) {
  //     return api.post(`${BASE_URL}/cluster-manager/schedule/infinity`, payload);
  //   }
}
