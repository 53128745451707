import { useState } from "react";
import CardListItem from "../../../../../shared/ui/CardListItem";
import ListWrapper from "../../../../../shared/ui/ListWrapper";
import CreateModal from "./CreateModal";

import { useQueryClient } from "@tanstack/react-query";
import {
  useBaseCurrencies,
  useCreateBaseCurrency,
  useDeleteBaseCurrency,
} from "../../../../../shared/api/query/use-admin/use-base-currencies";
import { showAlert } from "../../../../../app/alert/store";

const BaseCurrency = () => {
  const queryClient = useQueryClient();

  const { data: currencies } = useBaseCurrencies();

  const { mutateAsync: createCurrency } = useCreateBaseCurrency(queryClient);
  const { mutateAsync: deleteCurrency } = useDeleteBaseCurrency(queryClient);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <ListWrapper>
      <CreateModal
        open={isModalOpen}
        onChangeOpen={() => setIsModalOpen((isOpen) => !isOpen)}
        onCreate={(payload) => createCurrency(payload)}
      />

      {currencies?.length ? (
        currencies.map((item) => (
          <CardListItem
            key={item.base_currency_id}
            title={item.base_currency_code}
            onClickDelete={() =>
              showAlert({
                title: `Вы действительно хотите удалить валюту ${item.base_currency_code} ?`,
                onAgree: () => deleteCurrency(item),
              })
            }
          />
        ))
      ) : (
        <CardListItem title="Список валют пуст" />
      )}
    </ListWrapper>
  );
};

export default BaseCurrency;
