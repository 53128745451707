import { toast } from "../../../hooks/use-toast";

const baseErrors = {
  400: "Что-то пошло не так, обратитесь в отдел IT",
  422: "Поля были неправильно заполены",
  500: "Ошибка сервера",
};

export const toastRequestResults = (status: number, message?: string) => {
  if (status < 200 || status >= 300) {
    const baseToast = {
      variant: "destructive" as const,
    };
    if (message) toast({ ...baseToast, description: message });
    else
      Object.entries(baseErrors)?.forEach((error_el) => {
        if (status === +error_el[0]) {
          toast({ ...baseToast, description: error_el[1] as string });
          throw new Error(status?.toString());
        }
      });
    toast({ ...baseToast, description: "Неизвестная ошибка" });
  }
};
