import { BASE_URL } from "../../constants";
import api from "../api-instance";
import { ENDPOINT_COURSE } from "../endpoints";
import * as T from "../types";

export default class courseApi {
  static async getCourse(): T.PromiseCourses {
    return api.get(`${BASE_URL}/${ENDPOINT_COURSE}/`);
  }
}
