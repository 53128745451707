import adminService from "../../services/admin-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { TCurrency, TCreateCurrency } from "../../../types/pair-builder";
import { QueryClient } from "@tanstack/react-query";

export const useCurrencies = () =>
  useBaseQuery<null, TCurrency[]>(["get-currencies"], () => adminService.getCurrencies());

export const useCreateCurrency = (queryClient: QueryClient) =>
  useBaseMutation<TCreateCurrency, TCurrency>(
    (payload: TCreateCurrency) => adminService.addCurrency(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-currencies"] })
  );

export const useUpdateCurrency = (queryClient: QueryClient) =>
  useBaseMutation<TCurrency, TCurrency>(
    (payload: TCurrency) => adminService.updateCurrency(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-currencies"] })
  );

export const useDeleteCurrency = (queryClient: QueryClient) =>
  useBaseMutation<string, TCurrency>(
    (currency_code: string) => adminService.deleteCurrency(currency_code),
    () => queryClient.invalidateQueries({ queryKey: ["get-currencies"] })
  );
