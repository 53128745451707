import { Button } from "../../../../components/ui/button";
import { Plus } from "lucide-react";
import { Input } from "../../../../components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import { z } from "zod";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "../../../../components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { TCountry, TCreateCountry } from "../../../../shared/types/pair-builder";
import { countryCreateSchema, countryDefaultValues } from "./validation";

interface CreateModalProps {
  country?: TCountry;
  open: boolean;
  onChangeOpen: () => void;
  onEdit: (payload: TCountry) => void;
  onCreate: (payload: TCreateCountry) => void;
}

const CreateModal = ({ open, onChangeOpen, country, onEdit, onCreate }: CreateModalProps) => {
  const editMode = !!country;

  const countryCreateForm = useForm<z.infer<typeof countryCreateSchema>>({
    resolver: zodResolver(countryCreateSchema),
    values: editMode ? country : countryDefaultValues,
  });

  const onChangeOpenModalHandler = () => {
    if (open) countryCreateForm.reset(countryDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof countryCreateSchema>) => {
    if (editMode) onEdit({ ...values, country_id: country.country_id });
    else onCreate(values);
    onChangeOpenModalHandler();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogTrigger asChild>
        <Button
          className="self-end"
          variant="outline"
          size="icon">
          <Plus />
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{editMode ? "Редактировать страну" : "Новая страна"}</DialogTitle>
        </DialogHeader>

        <Form {...countryCreateForm}>
          <form
            onSubmit={countryCreateForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-4">
            <FormField
              control={countryCreateForm.control}
              name="country_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Страна</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={countryCreateForm.control}
              name="country_code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Код страны</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={editMode}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="submit">{editMode ? "ИЗМЕНИТЬ СТРАНУ" : "ДОБАВИТЬ СТРАНУ"}</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
