import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toastRequestResults } from "./toasts";

export function useBaseQuery<P, R>(
  queryKeys: string[],
  request: (payload?: P) => Promise<AxiosResponse<R, string>>,
  enabled: boolean = true
) {
  const { data, isPending, error } = useQuery({
    queryKey: queryKeys,
    queryFn: async () => {
      try {
        if (queryKeys?.some((el) => el === "")) {
          return null;
        }
        const response = await request();

        toastRequestResults(response.status);

        return response.data;
      } catch (e) {
        throw new Error(e as string);
      }
    },
    enabled: enabled,
  });

  return {
    data,
    isPending,
    error,
  };
}
