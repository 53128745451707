import { useBaseQuery } from "../base-query";
import adminService from "../../services/admin-service";
import { TCity, TCreateCity } from "../../../types/pair-builder";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";

export const useCities = () =>
  useBaseQuery<null, TCity[]>(["get-cities"], () => adminService.getCities());

export const useCreateCity = (queryClient: QueryClient) =>
  useBaseMutation<TCreateCity, TCity>(
    (payload: TCreateCity) => adminService.addCity(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-cities"] })
  );

export const useUpdateCity = (queryClient: QueryClient) =>
  useBaseMutation<TCity, TCity>(
    (payload: TCity) => adminService.updateCity(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-cities"] })
  );
