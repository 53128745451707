import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";
import tetrisApi from "../../services/tetris-service";
import { TBestSite, TBlacklist, TCreateBlacklist } from "../../../types/tetris";
import bestAnalyticsApi from "../../services/best-analytics-service";

export const useBlacklist = () =>
  useBaseQuery<null, TBlacklist[]>(["get-blacklist"], () => tetrisApi.getTetrisBlacklist());

export const useCreateBlacklist = (queryClient: QueryClient) =>
  useBaseMutation<TCreateBlacklist, TBlacklist>(
    (payload: TCreateBlacklist) => tetrisApi.createTetrisBlacklist(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-blacklist"] })
  );

export const useDeleteBlacklist = (queryClient: QueryClient) =>
  useBaseMutation<TBlacklist[], TBlacklist[]>(
    (payload: TBlacklist[]) => tetrisApi.deleteTetrisBlacklist(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-blacklist"] })
  );

export const useBestSites = () =>
  useBaseQuery<boolean, TBestSite[]>(["get-best-sites"], () => bestAnalyticsApi.getBestSites());
