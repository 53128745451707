import { Toggle } from "../../../components/ui/toggle";

type SimpleItemProps = {
  isToggle: boolean;
  onSelectValue?: () => void;
  value: string;
};

const SimpleItem = ({ isToggle, onSelectValue, value }: SimpleItemProps) => {
  return (
    <Toggle
      size="lg"
      className="w-[145px] lg:w-[155px] px-[10px] pt-2 mx-0 pb-[10px] h-[30px] relative"
      style={{
        backgroundColor: isToggle ? `#4B7F6233` : "#FAFAFF",
        fontWeight: isToggle ? "600" : "400",
      }}
      pressed={isToggle}
      onPressedChange={onSelectValue}>
      <div className="flex justify-between items-center">{value}</div>
    </Toggle>
  );
};

export default SimpleItem;
