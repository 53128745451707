import { motion } from "framer-motion";
import { LayoutDashboard, EyeIcon, EyeClosedIcon, SettingsIcon } from "lucide-react";
import { PropsWithChildren, useState } from "react";
import { SidebarBody, SidebarLink, SidebarUI } from "../../components/ui/sidebar";
import { cn } from "../../shared/lib/utils";
import { ROUTES } from "../../app/route/routes";

const Sidebar = ({ children, hidden = false }: PropsWithChildren<{ hidden?: boolean }>) => {
  const links = [
    {
      label: "Автокор",
      href: ROUTES.AUTOCORR,
      icon: (
        <LayoutDashboard className="text-neutral-700 dark:text-neutral-200 h-5 w-5 flex-shrink-0" />
      ),
    },
    {
      label: "Сущности",
      href: ROUTES.ENTITY,
      icon: (
        <SettingsIcon className="text-neutral-700 dark:text-neutral-200 h-5 w-5 flex-shrink-0" />
      ),
    },
    {
      label: "BLACKLIST",
      href: ROUTES.BLACKLIST,
      icon: (
        <LayoutDashboard className="text-neutral-700 dark:text-neutral-200 h-5 w-5 flex-shrink-0" />
      ),
    },
    {
      label: "Шаги",
      href: ROUTES.STEPS,
      icon: (
        <LayoutDashboard className="text-neutral-700 dark:text-neutral-200 h-5 w-5 flex-shrink-0" />
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  return (
    <>
      {hidden ? (
        children
      ) : (
        <div
          className={cn(
            "flex flex-col md:flex-row bg-neutral-800 w-full flex-1  mx-auto border border-neutral-700 overflow-hidden",
            "h-full"
          )}>
          <SidebarUI
            open={open}
            setOpen={setOpen}>
            <SidebarBody className="justify-between gap-10">
              <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden pt-[60px]">
                {/* {open ? <Logo /> : <LogoIcon />} */}
                <div className="mt-8 flex flex-col gap-2">
                  {links.map((link, idx) => (
                    <SidebarLink
                      key={idx}
                      link={link}
                    />
                  ))}
                </div>
              </div>
            </SidebarBody>
          </SidebarUI>
          <div className="flex flex-1">
            <div className=" border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-900 flex flex-col gap-2 flex-1 pt-[60px]">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const Logo = () => {
  return (
    <EyeIcon
      href="#"
      className="font-normal flex space-x-2 items-center text-sm text-black py-1 relative z-20">
      <div className="h-5 w-6 bg-black dark:bg-white rounded-br-lg rounded-tr-sm rounded-tl-lg rounded-bl-sm flex-shrink-0" />
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="font-medium text-black dark:text-white whitespace-pre">
        Luxary Course 2.0
      </motion.span>
    </EyeIcon>
  );
};

export const LogoIcon = () => {
  return (
    <EyeClosedIcon
      href="#"
      className="font-normal flex space-x-2 items-center text-sm text-black py-1 relative z-20">
      <div className="h-5 w-6 bg-black dark:bg-white rounded-br-lg rounded-tr-sm rounded-tl-lg rounded-bl-sm flex-shrink-0" />
    </EyeClosedIcon>
  );
};

export default Sidebar;
