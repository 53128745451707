import { Button } from "../../../../../components/ui/button";
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  Dialog,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { useOTPModalStore } from "./store";
import QRCode from "react-qr-code";

const OTPModal = () => {
  const { user_name, user_role_name, user_otp_key, user_password, onOpenChange, ...props } =
    useOTPModalStore();
  return (
    <Dialog
      {...props}
      onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{"OTP"}</DialogTitle>
        </DialogHeader>

        <div style={{ height: "auto", margin: "0 auto 30px auto", maxWidth: 300, width: "100%" }}>
          <QRCode
            size={200}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`otpauth://totp/${user_name}?secret=${user_otp_key}`}
            viewBox={`0 0 200 200`}
          />
        </div>

        <div className={"flex justify-between"}>
          <div className="text text-left text-font">Логин</div>
          <div className="text text-left font-light text-font">{user_name}</div>
        </div>

        {user_password && (
          <div className={"flex justify-between"}>
            <div className="text text-left text-font">Пароль</div>
            <div className="text text-left font-light text-font">{user_password}</div>
          </div>
        )}

        <div className={"flex justify-between"}>
          <div className="text text-left text-font">Роль</div>
          <div className="text text-left font-light text-font">{user_role_name}</div>
        </div>

        <div className="small-text">Не потеряйте QR-код, он генерируется только один раз</div>

        <DialogFooter className="flex sm:flex-col flex-col gap-2">
          <Button
            type="submit"
            onClick={onOpenChange}>
            {" "}
            {"Подтвердить"}{" "}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default OTPModal;
