import { BASE_URL } from "../../constants";
import api from "../api-instance";
import { ENDPOINT_BEST_ANALITICS } from "../endpoints";
import * as T from "../types";

export default class bestAnalyticsApi {
  static async getBestSites(): T.GetPromiseBlacklistSites {
    return api.get(`${BASE_URL}/${ENDPOINT_BEST_ANALITICS}/best-sites/`);
  }
}
