import { Button } from "../../../../../components/ui/button";
import { Plus } from "lucide-react";
import { Input } from "../../../../../components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { z } from "zod";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "../../../../../components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ToggleGroup, ToggleGroupItem } from "../../../../../components/ui/toggle-group";
import { currencyCreateSchema, currencyDefaultValues } from "./validation";
import { CurrencyTypeEnum } from "../../../../../shared/types/common";
import { TCurrency, TCreateCurrency } from "../../../../../shared/types/pair-builder";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { useBaseCurrencies } from "../../../../../shared/api/query/use-admin/use-base-currencies";

interface CreateModalProps {
  currency?: TCurrency;
  open: boolean;
  onChangeOpen: () => void;
  onEdit: (payload: TCurrency) => void;
  onCreate: (payload: TCreateCurrency) => void;
}
const CreateModal = ({ open, onChangeOpen, currency, onEdit, onCreate }: CreateModalProps) => {
  const { data: baseCurrencies } = useBaseCurrencies();
  const editMode = !!currency;

  const currencyCreateForm = useForm<z.infer<typeof currencyCreateSchema>>({
    resolver: zodResolver(currencyCreateSchema),
    values: editMode ? currency : currencyDefaultValues,
  });

  const onChangeOpenModalHandler = () => {
    if (open) currencyCreateForm.reset(currencyDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof currencyCreateSchema>) => {
    const valuesWithNumber = {
      ...values,
      currency_bestchange_id: Number(values.currency_bestchange_id),
      currency_exnode_id: Number(values.currency_exnode_id),
    };

    if (editMode) onEdit({ ...valuesWithNumber, currency_id: currency.currency_id } as TCurrency);
    else onCreate(valuesWithNumber as TCreateCurrency);

    onChangeOpenModalHandler();
  };


  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogTrigger asChild>
        <Button
          className="self-end"
          variant="outline"
          size="icon">
          <Plus />
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{editMode ? "Редактировать валюту" : "Новая валюта"}</DialogTitle>
        </DialogHeader>

        <Form {...currencyCreateForm}>
          <form
            onSubmit={currencyCreateForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-4">
            <FormField
              control={currencyCreateForm.control}
              name="currency_type"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <ToggleGroup
                      type="single"
                      value={field.value}
                      onValueChange={field.onChange}>
                      {Object.values(CurrencyTypeEnum).map((type) => (
                        <ToggleGroupItem
                          value={type}
                          className="data-[state=on]:bg-black data-[state=on]:text-white">
                          {type}
                        </ToggleGroupItem>
                      ))}
                    </ToggleGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={currencyCreateForm.control}
              name="currency_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Валюта*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={currencyCreateForm.control}
              name="currency_code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Код валюты*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={currencyCreateForm.control}
              name="currency_exnode_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>ID на exnode*</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={currencyCreateForm.control}
              name="currency_bestchange_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>ID на bestchange*</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={currencyCreateForm.control}
              name="currency_bestchange_url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>URL bestchange*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={currencyCreateForm.control}
              name="currency_base_code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Базовая валюта</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value ?? ""}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {baseCurrencies?.map((base) => (
                        <SelectItem value={base.base_currency_code}>
                          {base.base_currency_code}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="submit">{editMode ? "ИЗМЕНИТЬ ВАЛЮТУ" : "ДОБАВИТЬ ВАЛЮТУ"}</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
