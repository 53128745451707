import { useMemo } from "react";
import { RateItem } from "../../../../shared/store/rate-store";
import { TGlobalDirections, TGlobalVector } from "../../../../shared/types/pair-builder";
import { CurrencyIcons } from "../../../../shared/ui/CurrencyIcons";
import { Toggle } from "../../../../components/ui/toggle";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";

type CurrencyItemProps = {
  onSelectVector?: (vector: TGlobalDirections) => void;
  rates?: RateItem[];
  autocorr_value?: number;
  isToggle: boolean;
  vector: TGlobalDirections;
  baseCodeMap: Map<string, string>;
};

const getRateByRates = (
  rates: RateItem[],
  baseCodeMap: Map<string, string>,
  vector: TGlobalVector
) => {
  const rateMap = new Map<string, number>();
  rates.forEach(({ currency_code_from, currency_code_to, course_price }) => {
    if (baseCodeMap.get(currency_code_from) && baseCodeMap.get(currency_code_to)) {
      rateMap.set(
        `${baseCodeMap.get(currency_code_from)}-${baseCodeMap.get(currency_code_to)}`,
        course_price
      );
    }
  });

  const { currency_code: from, currency_base_code: baseFrom } = vector.currency_from;
  const { currency_code: to, currency_base_code: baseTo } = vector.currency_to;

  const keysToCheck = [
    `${from}-${to}`,
    baseFrom ? `${baseFrom}-${to}` : "",
    baseTo ? `${from}-${baseTo}` : "",
    baseFrom && baseTo ? `${baseFrom}-${baseTo}` : "",
  ].filter(Boolean);

  const keysToCheckNegative = [
    `${to}-${from}`,
    baseFrom ? `${to}-${baseFrom}` : "",
    baseTo ? `${baseTo}-${from}` : "",
    baseFrom && baseTo ? `${baseTo}-${baseFrom}` : "",
  ].filter(Boolean);

  let rate = 0;
  for (const key of keysToCheck) {
    const rateFromMap = rateMap.get(key);
    if (rateFromMap) {
      rate = rateFromMap;
      break;
    }
  }

  if (rate < 1) {
    for (const key of keysToCheckNegative) {
      const rateFromMap = rateMap.get(key);
      if (rateFromMap) {
        rate = rateFromMap;
        break;
      }
    }
  }

  return rate;
};

const CurrencyItem = ({
  vector,
  rates,
  autocorr_value,
  onSelectVector,
  isToggle,
  baseCodeMap,
}: CurrencyItemProps) => {
  const rate = useMemo(
    () => getRateByRates(rates ?? [], baseCodeMap, vector),
    [baseCodeMap, rates, vector]
  );

  const result = useMemo(() => {
    if (rates) {
      if (autocorr_value) return rate * ((100 + autocorr_value) / 100);
      else return rate;
    }
    return undefined;
  }, [autocorr_value, rate, rates]);

  const onSelectVectorHandler = () => {
    onSelectVector?.(!Number.isNaN(rate) && !!rate ? { ...vector, rate: rate } : vector);
  };

  return (
    <Toggle
      size="lg"
      className="w-[145px] lg:w-[155px] pb-[10px] mx-0 h-[60px] relative"
      style={{
        backgroundColor: isToggle ? `#4B7F6233` : "#FAFAFF",
        fontWeight: isToggle ? "600" : "400",
      }}
      pressed={isToggle}
      onPressedChange={onSelectVectorHandler}>
      <div className="flex justify-between items-center">
        <div className="flex flex-col items-center">
          <CurrencyIcons type={vector.currency_from.currency_code} />
          <div className="text-[10px] w-[55px] lg:w-[60px]">
            {vector.currency_from.currency_code}
          </div>
        </div>
        <div style={{ color: "#AAA" }}>{`\u279C`}</div>
        <div className="flex flex-col items-center">
          <CurrencyIcons type={vector.currency_to.currency_code} />
          <div className="text-[10px] w-[55px] lg:w-[60px]">{vector.currency_to.currency_code}</div>
        </div>
      </div>

      {!Number.isNaN(result) && !!result && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="absolute bottom-1 text-[9px]">
                <div className="mt-[14px] bg-[#BABAC3AA] rounded-sm px-4 py-[1px] leading-[9px]">
                  {result}
                </div>
              </div>
            </TooltipTrigger>
            <TooltipContent>{`Результирующий курс\nот курса биржи\n${result}`}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </Toggle>
  );
};

export default CurrencyItem;
