import { create } from "zustand";

export interface RateItem {
  currency_code_from: string;
  currency_code_to: string;
  course_price: number;
  pair_name: string;
}

interface RatesState {
  rates: RateItem[];
  setRates: (newRate: RateItem) => void;
  setInitRates: (newRates: RateItem[]) => void;
}

export const useRatesStore = create<RatesState>((set) => ({
  rates: [],
  setInitRates: (newRates) => set({ rates: newRates }),
  setRates: (newRate) =>
    set((state) => ({
      rates: [...state.rates.filter((r) => r.pair_name !== newRate.pair_name), newRate],
    })),
}));
