import { useMemo } from "react";
import { useGlobal } from "../../shared/api/query/use-admin/use-global";
import { Accordion } from "../../components/ui/accordion";
import { StepItem, TUpdateStep } from "../../shared/types/pair-builder";
import { Card } from "../../components/ui/card";
import StepGroup from "./StepGroup";
import ListWrapper from "../../shared/ui/ListWrapper";

export interface AllCityStep {
  updatedSteps: Omit<TUpdateStep, "site_name">[];
  setUpdatedSteps: (steps: Omit<TUpdateStep, "site_name">[]) => void;
}

const AllCityStep = ({ updatedSteps, setUpdatedSteps }: AllCityStep) => {
  const { data: globals } = useGlobal();

  const vectors = globals?.currency_pairs.map((v) => ({
    currency_code_from: v.currency_from.currency_code,
    currency_code_to: v.currency_to.currency_code,
  }));

  const code_from_group = useMemo(
    () => [...new Set(vectors?.map((el) => el.currency_code_from))],
    [vectors]
  );

  const updateStepsHandler = (step: StepItem) => {
    setUpdatedSteps(
      updatedSteps.find(
        (item) =>
          item.currency_code_from === step.currency_code_from &&
          item.currency_code_to === step.currency_code_to
      )
        ? updatedSteps.map((item) => {
            if (
              item.currency_code_from === step.currency_code_from &&
              item.currency_code_to === step.currency_code_to
            )
              return { ...item, ...step };
            else return item;
          })
        : [
            ...updatedSteps,
            {
              ...step,
              step_max_value: step.step_max_value ?? 0,
              step_min_value: step.step_min_value ?? 0,
            },
          ]
    );
  };

  return (
    <ListWrapper className="w-fit">
      <Card>
        <div className="grid grid-cols-[2fr_1fr_1fr] h-[40px] px-8 items-center rounded-t-xl text-white  bg-[rgb(40,44,52)]">
          <div className="text-center font-bold">ВЕКТОР</div>
          <div className="text-center font-bold">MIN ШАГ</div>
          <div className="text-center font-bold">MAX ШАГ</div>
        </div>

        <Accordion
          type="multiple"
          className="px-8 py-5 pt-3">
          {code_from_group.map((group) => (
            <StepGroup
              key={group}
              code_from={group}
              steps={vectors ?? []}
              updateSteps={updateStepsHandler}
            />
          ))}
        </Accordion>
      </Card>
    </ListWrapper>
  );
};

export default AllCityStep;
