import { useMemo, useState } from "react";
import FilterSelector from "../components/CurrencyDirection/FilterSelector";
import SelectAllButton from "../components/SelectAllButton";
import { Option } from "../../../components/ui/multiple-selector";
import SimpleItem from "../components/SimpleItem";
import { TCity } from "../../../shared/types/pair-builder";
import { TAutocorrCity } from "../../../shared/types/tetris";

interface CitiesProps {
  allCities: TCity[];
  selectedCities: TAutocorrCity[];
  onSelectCities: (cities: TAutocorrCity[]) => void;
}

const Cities = ({ allCities, selectedCities, onSelectCities }: CitiesProps) => {
  const cityOption = useMemo(
    () => allCities.map((c) => ({ value: c.city_code, label: c.city_name })),
    [allCities]
  );

  const [filter, setFilter] = useState<Option[]>([]);

  const filteredCities = useMemo(
    () =>
      allCities.filter(
        (item) => filter.length === 0 || filter.some((opt) => opt.value === item.city_code)
      ),
    [allCities, filter]
  );

  const onSelectCity = (city: TAutocorrCity) => {
    const updatedSelectedCities = selectedCities.find((c) => c.city_code === city.city_code)
      ? selectedCities.filter((c) => c.city_code !== city.city_code)
      : [...selectedCities, city];

    onSelectCities(updatedSelectedCities);
  };

  return (
    <div>
      <div className="flex pb-1 lg:pb-4">
        <FilterSelector
          options={cityOption}
          placeholder="city"
          value={filter}
          onChange={setFilter}
        />
      </div>

      <SelectAllButton onClickAll={(flag) => onSelectCities(flag ? filteredCities : [])} />

      <div className="grid grid-cols-1 gap-1">
        <div className="flex flex-col gap-1 w-[148px] lg:w-[160px]">
          {filteredCities?.map((el) => (
            <SimpleItem
              {...el}
              key={el.city_name}
              isToggle={!!selectedCities.find((c) => c.city_code === el.city_code)}
              onSelectValue={() => onSelectCity(el)}
              value={el.city_name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cities;
