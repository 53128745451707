import { Button } from "../../../../components/ui/button";
import { Plus } from "lucide-react";
import { Input } from "../../../../components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import { z } from "zod";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
  FormLabel,
} from "../../../../components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { userCreateSchema, userDefaultValues } from "./validation";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { TCreateUser } from "../../../../shared/types/pair-builder";
import { EUserRole } from "../../../../shared/types/common";

interface EditModalProps {
  open: boolean;
  onChangeOpen: () => void;
  onCreate?: (values: TCreateUser) => void;
}

const CreateUserModal = ({ open, onChangeOpen, onCreate }: EditModalProps) => {
  const userCreateUserForm = useForm<z.infer<typeof userCreateSchema>>({
    resolver: zodResolver(userCreateSchema),
  });

  const onChangeOpenModalHandler = () => {
    if (open) userCreateUserForm.reset(userDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof userCreateSchema>) => {
    onCreate?.(values);
    onChangeOpenModalHandler();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogTrigger asChild>
        <Button
          className="self-end"
          variant="outline"
          size="icon">
          <Plus />
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px] gap-5">
        <DialogHeader>
          <DialogTitle>{"Новый пользователь"}</DialogTitle>
        </DialogHeader>

        <Form {...userCreateUserForm}>
          <form
            onSubmit={userCreateUserForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-4">
            <FormField
              control={userCreateUserForm.control}
              name="user_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Логин</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={userCreateUserForm.control}
              name="user_password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Пароль</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="от 12 символов"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={userCreateUserForm.control}
              name="user_role_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Роль</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Выберите роль пользователя" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.values(EUserRole).map((item) => (
                        <SelectItem
                          key={item}
                          value={item}>
                          {item}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="submit"> {"Добавить"}</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserModal;
