import { z } from "zod";
import { user_username_schema, user_password_schema } from "../../../../shared/validation";
import { EUserRole } from "../../../../shared/types/common";

export const userCreateSchema = z.object({
  user_name: user_username_schema,
  user_password: user_password_schema,
  user_role_name: z.nativeEnum(EUserRole),
});

export const userEditSchema = z.object({
  user_password: user_password_schema,
});

export const userDefaultValues = {
  user_name: "",
  user_role_name: EUserRole.ADMIN,
  user_password: "",
};
