import { PropsWithChildren } from "react";
import { cn } from "../../lib/utils";

const ListWrapper = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={cn("flex justify-center w-full", className && className)}>
      <div className="flex flex-col gap-2 md:w-[580px]">{children}</div>
    </div>
  );
};

export default ListWrapper;
