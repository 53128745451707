import { z } from "zod";

const loginRegex = /[^\s"]*$/;
export const string_schema = z.string({ required_error: "Это обязательное поле" });

export const user_username_schema = z
  .string({ required_error: "Это обязательное поле" })
  .min(1, { message: "Это обязательное поле" })
  .regex(loginRegex, { message: "Только латинские символы и цифры" });

export const user_password_schema = z
  .string({ required_error: "Это обязательное поле" })
  .min(12, { message: "Пароль от 12 символов" });
