import Alert from "../../shared/ui/Alert";
import { useAlertStore } from "./store";

const AlertProvider = () => {
  const { ...props } = useAlertStore();

  return <Alert {...props} />;
};

export default AlertProvider;
