import { z } from "zod";
import { CurrencyTypeEnum } from "../../../../../shared/types/common";

export const currencyCreateSchema = z.object({
  currency_name: z.string().min(1, "Поле обязательно").max(100, "Не больше 100 символов"),
  currency_code: z.string().min(1, "Поле обязательно").max(100, "Не больше 25 символов"),
  currency_type: z.string().min(1, "Поле обязательно").max(100, "Не больше 100 символов"),
  currency_exnode_id: z.union([z.coerce.string().min(1, "Поле обязательно"), z.number()]),
  currency_bestchange_id: z.union([z.coerce.string().min(1, "Поле обязательно"), z.number()]),
  currency_bestchange_url: z.string().min(1, "Поле обязательно").max(100, "Не больше 100 символов"),
  currency_base_code: z.string().optional().nullable(),
  countries: z.array(z.object({ country_code: z.string() })).optional(),
});

export const currencyDefaultValues = {
  currency_name: "",
  currency_code: "",
  currency_type: CurrencyTypeEnum.crypto,
  currency_exnode_id: "",
  currency_bestchange_id: "",
  currency_bestchange_url: "",
  currency_base_code: undefined,
  countries: undefined,
};
