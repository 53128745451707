import { PropsWithChildren } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../components/ui/alert-dialog";

export type AlertProps = PropsWithChildren<{
  open: boolean;
  onOpenChange: () => void;

  title?: string;
  description?: string;
  cancelButtonText?: string;
  agreeButtonText?: string;
  onAgree?: () => void;

  showCancelButton?: boolean;
}>;

const Alert = ({
  open,
  onOpenChange,
  title,
  description,
  onAgree,
  cancelButtonText = "Отменить",
  agreeButtonText = "Подтвердить",
  showCancelButton = true,
  children = undefined,
}: AlertProps) => {
  return (
    <AlertDialog
      open={open}
      onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          {title && <AlertDialogTitle>{title}</AlertDialogTitle>}
        </AlertDialogHeader>

        {description && <AlertDialogDescription>{description}</AlertDialogDescription>}

        {children && children}

        <AlertDialogFooter>
          {showCancelButton && <AlertDialogCancel>{cancelButtonText}</AlertDialogCancel>}
          <AlertDialogAction onClick={onAgree}>{agreeButtonText}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Alert;
