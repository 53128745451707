import { centrifuge } from ".";
import { useRatesStore } from "../../store/rate-store";

interface RateItem {
  currency_code_from: string;
  currency_code_to: string;
  course_price: number;
  pair_name: string;
}

interface SubscriptionOptions {
  resubscribe?: boolean;
  unsubscribe?: boolean;
}

export const convertRateItem = (item: RateItem): RateItem => ({
  ...item,
  pair_name: `${item.currency_code_from}-${item.currency_code_to}`,
  course_price: Number(item.course_price),
});

export const createRatesSubscription = (options: SubscriptionOptions | void) => {
  const { setRates } = useRatesStore.getState();

  const subscription = centrifuge.subscribe("direction:EXCHANGE_COURSES", (res) => {
    setRates(convertRateItem(res.data));
  });

  if (options?.resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe("direction:EXCHANGE_COURSES", (res) => {
      setRates(convertRateItem(res.data));
    });
  } else if (options?.unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
};
