import { useMemo } from "react";
import { Checkbox } from "../../../../components/ui/checkbox";
import { cn } from "../../../../shared/lib/utils";
import { TBlacklist } from "../../../../shared/types/tetris";
import ListToggle from "../../components/ListToggle";

interface ItemProps {
  blacklist: TBlacklist;
  pickOptions: Partial<TBlacklist>;
  setPick: (options: Partial<TBlacklist>) => void;
  selected: TBlacklist[];
  setSelected: (selected: TBlacklist[]) => void;
}

const BlacklistItem = ({ blacklist, pickOptions, setPick, selected, setSelected }: ItemProps) => {
  const { site_name, city_name } = blacklist;
  const direction = `${blacklist.currency_code_from}-to-${blacklist.currency_code_to}`;

  const options = { site_name, direction, city_name };

  const isSelected = useMemo(
    () => !!selected.find((a) => a.blacklist_id === blacklist.blacklist_id),
    [selected]
  );

  const onSelectItem = () => {
    setSelected(
      isSelected
        ? selected.filter((i) => i.blacklist_id !== blacklist.blacklist_id)
        : [...selected, blacklist]
    );
  };

  return (
    <div className={cn("flex flex-row flex-wrap gap-1 text-xs rounded-lg p-1")}>
      <Checkbox
        className="rounded-full h-[20px] w-[20px] border-[hsl(var(--input))] bg-white data-[state=checked]:bg-[var(--autocorr-theme)]"
        checked={isSelected}
        onCheckedChange={onSelectItem}
      />

      {Object.entries(options).map(([key, value]) => {
        const isPressed = pickOptions[key as keyof TBlacklist] === value;
        return (
          <ListToggle
            key={key + value + blacklist.blacklist_id}
            value={value.toString()}
            pressed={isPressed}
            onPressedChange={() =>
              setPick({ ...pickOptions, [key]: isPressed ? undefined : value })
            }
          />
        );
      })}
    </div>
  );
};

export default BlacklistItem;
