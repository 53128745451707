import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { useBaseMutationWithoutPayload } from "../base-mutation-without-payload";
import adminService from "../../services/admin-service";
import authService from "../../services/auth-service";
import { TLogin } from "../../../types/auth";
import { TUser } from "../../../types/common";

export const useAuth = () => useBaseQuery<null, TUser>(["me"], () => adminService.me());

export const useLogin = () =>
  useBaseMutation<TLogin, TUser>(async (payload: TLogin) => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const user_fingerprint = result.visitorId;

    return await authService.login({
      ...payload,
      user_fingerprint,
    });
  });

export const useLogout = () => useBaseMutationWithoutPayload<TUser>(() => authService.logout());
