import { useBaseQuery } from "../base-query";
import adminService from "../../services/admin-service";
import { TCreateUser, TEditUser, TUserWithOTP } from "../../../types/pair-builder";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";
import { TUser } from "../../../types/common";
import { showOTP } from "../../../../pages/entity-manager/entities/UserList/OTPModal/store";

export const useUsers = () =>
  useBaseQuery<null, TUser[]>(["get-users"], () => adminService.getUsers());

export const useCreateUser = (queryClient: QueryClient) =>
  useBaseMutation<TCreateUser, TUserWithOTP>(
    (payload: TCreateUser) => adminService.addUser(payload),
    (data: TUserWithOTP | null, variables?: TCreateUser) => {
      queryClient.invalidateQueries({ queryKey: ["get-users"] });
      if (data) showOTP({ ...data, user_password: variables?.user_password });
    }
  );

export const useUpdateUser = (queryClient: QueryClient) =>
  useBaseMutation<TEditUser, TUser>(
    (payload: TEditUser) => adminService.updateUser(payload),
    (data: TUserWithOTP | null) => {
      queryClient.invalidateQueries({ queryKey: ["get-users"] });
      if (data && data.user_otp_key) showOTP(data);
    }
  );

export const useDeleteUser = (queryClient: QueryClient) =>
  useBaseMutation<string, TUser>(
    (user_id: string) => adminService.deleteUser(user_id),
    () => queryClient.invalidateQueries({ queryKey: ["get-users"] })
  );
