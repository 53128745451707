import { useMemo, useState } from "react";
import { Button } from "../../../components/ui/button";
import { useCities } from "../../../shared/api/query/use-admin/use-cities";
import { useGlobal } from "../../../shared/api/query/use-admin/use-global";
import { TGlobalVector } from "../../../shared/types/pair-builder";
import { TAutocorrCity, TAutocorrSite } from "../../../shared/types/tetris";
import Cities from "../components/Cities";
import CurrencyDirection from "../components/CurrencyDirection/CurrencyDirection";
import Sites from "../components/Sites";
import {
  TetrisBody,
  TetrisContent,
  TetrisFooter,
  TetrisWrapper,
} from "../components/TetrisWrapper";
import { Switch } from "../../../components/ui/switch";
import { Label } from "../../../components/ui/label";
import { showConfirmAlert } from "../components/Alerts";
import {
  useBestSites,
  useCreateBlacklist,
} from "../../../shared/api/query/use-admin/use-blacklist";
import { useQueryClient } from "@tanstack/react-query";
import BlacklistList from "./BlacklistList";

const Blacklist = () => {
  const queryClient = useQueryClient();

  const [isSiteIsOur, setSiteIsOur] = useState(true);

  const { data: allCities } = useCities();
  const { data: global } = useGlobal();
  const { data: allSites } = useBestSites();

  const siteMap = useMemo(() => {
    const map = new Map<string, boolean>();

    allSites?.forEach(({ site_name, site_is_our }) => map.set(site_name, site_is_our));

    return map;
  }, [allSites]);

  const [selectedVectors, setSelectedVectors] = useState<TGlobalVector[]>([]);
  const [selectedCities, setSelectedCities] = useState<TAutocorrCity[]>([]);
  const [selectedSites, setSelectedSites] = useState<TAutocorrSite[]>([]);

  const { mutateAsync: createBlacklist } = useCreateBlacklist(queryClient);

  const filteredSites = useMemo(
    () => allSites?.filter((s) => s.site_is_our === isSiteIsOur),
    [allSites, isSiteIsOur]
  );

  return (
    <TetrisWrapper>
      <TetrisContent>
        <div className="flex items-center space-x-2 px-[10px] pt-[20px]">
          <Switch
            checked={isSiteIsOur}
            onCheckedChange={setSiteIsOur}
          />
          <Label>{isSiteIsOur ? "Наши сайты" : "Не наши сайты"}</Label>
        </div>
        <TetrisBody>
          <CurrencyDirection
            vectors={global?.currency_pairs ?? []}
            selectedVectors={selectedVectors}
            onSelectVectors={setSelectedVectors}
          />

          <Cities
            allCities={allCities ?? []}
            selectedCities={selectedCities}
            onSelectCities={setSelectedCities}
          />

          <Sites
            allSites={filteredSites ?? []}
            selectedSites={selectedSites}
            onSelectSites={setSelectedSites}
          />
        </TetrisBody>

        <TetrisFooter>
          <Button
            className="w-[216px] lg:w-[199px] text-white bg-black"
            onClick={() =>
              showConfirmAlert({
                selectedCities,
                selectedVectors,
                selectedSites,
                onAgree: () =>
                  createBlacklist({
                    site_names: selectedSites.map((site) => site.site_name),
                    city_codes: selectedCities.map((city) => city.city_code),
                    vectors: selectedVectors.map((vector) => ({
                      currency_code_from: vector.currency_from.currency_code,
                      currency_code_to: vector.currency_to.currency_code,
                    })),
                  }),
              })
            }>
            Занести в blacklist
          </Button>
        </TetrisFooter>
      </TetrisContent>

      <BlacklistList siteMap={siteMap} />
    </TetrisWrapper>
  );
};

export default Blacklist;
