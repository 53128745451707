import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { ROUTES } from "./routes";
import { Auth } from "../../pages/auth";
import { useAuthStore } from "../../shared/store/auth-store";
import Autocorr from "../../pages/tetris/Autocore";
import Blacklist from "../../pages/tetris/Blacklist";
import EntityManager from "../../pages/entity-manager";
import Steps from "../../pages/steps";

const Routing = () => {
  const { me } = useAuthStore();

  return (
    <Routes>
      <Route
        path={ROUTES.AUTH}
        element={<Auth />}
      />
      {me && (
        <Route element={<Layout />}>
          <Route
            path={ROUTES.DEFAULT}
            element={<Autocorr />}
          />
          <Route
            path={ROUTES.ENTITY}
            element={<EntityManager />}
          />
          <Route
            path={ROUTES.AUTOCORR}
            element={<Autocorr />}
          />
          <Route
            path={ROUTES.BLACKLIST}
            element={<Blacklist />}
          />
          <Route
            path={ROUTES.STEPS}
            element={<Steps />}
          />
        </Route>
      )}

      <Route
        path="*"
        element={
          <Navigate
            to={ROUTES.AUTH}
            replace
          />
        }
      />
    </Routes>
  );
};

const Layout = () => {
  return <Outlet />;
};

export default Routing;
