import { FC, PropsWithChildren } from "react";
import { Trash, Pencil } from "lucide-react";
import { Card } from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";

interface CardListItemProps {
  title: string;
  description?: string;
  prefixIcon?: string;

  onClickDelete?: () => void;
  onClickEdit?: () => void;
}

const CardListItem: FC<PropsWithChildren<CardListItemProps>> = ({
  title,
  description,
  onClickDelete,
  onClickEdit,
  children,
}) => {
  return (
    <Card className="py-1 px-4 flex gap-2">
      <div className="w-full content-center">{title}</div>
      <div className="content-center text-xs">{description}</div>

      {children && <div className="w-full content-center">{children}</div>}

      {onClickEdit && (
        <Button
          onClick={onClickEdit}
          variant="ghost"
          size="icon">
          <Pencil height="20" />
        </Button>
      )}

      {onClickDelete && (
        <Button
          onClick={onClickDelete}
          variant="ghost"
          size="icon">
          <Trash height="20" />
        </Button>
      )}
    </Card>
  );
};

export default CardListItem;
