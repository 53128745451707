import adminService from "../../services/admin-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { TBaseCurrency, TBaseCurrencyCode } from "../../../types/pair-builder";
import { QueryClient } from "@tanstack/react-query";

export const useBaseCurrencies = () =>
  useBaseQuery<null, TBaseCurrency[]>(["get-base-currencies"], () =>
    adminService.getBaseCurrencies()
  );

export const useCreateBaseCurrency = (queryClient: QueryClient) =>
  useBaseMutation<TBaseCurrencyCode, TBaseCurrency>(
    (payload: TBaseCurrencyCode) => adminService.addBaseCurrency(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-base-currencies"] })
  );

export const useDeleteBaseCurrency = (queryClient: QueryClient) =>
  useBaseMutation<TBaseCurrencyCode, TBaseCurrency>(
    (payload: TBaseCurrencyCode) => adminService.deleteBaseCurrency(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-base-currencies"] })
  );
