import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../components/ui/tabs";
import ListWrapper from "../../../../shared/ui/ListWrapper";
import BaseCurrency from "./base-currency";
import Currency from "./currency";

const CurrencyList = () => {
  return (
    <ListWrapper>
      <Tabs
        defaultValue="account"
        className="pt-4">
        <TabsList className="h-[40px]">
          <TabsTrigger value="account">Валюты</TabsTrigger>
          <TabsTrigger value="password">Базовые валюты</TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          <Currency />
        </TabsContent>
        <TabsContent value="password">
          <BaseCurrency />
        </TabsContent>
      </Tabs>
    </ListWrapper>
  );
};

export default CurrencyList;
