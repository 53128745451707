import { AccordionItem, AccordionTrigger, AccordionContent } from "../../components/ui/accordion";
import { StepItem, TStep } from "../../shared/types/pair-builder";
import StepRow from "./StepRow";

interface StepProps {
  code_from: string;
  steps: StepItem[];
  updateSteps: (step: StepItem | TStep) => void;
}

const StepGroup = ({ code_from, steps, updateSteps }: StepProps) => {
  const stepState = steps.filter((step) => step.currency_code_from === code_from);

  return (
    <AccordionItem value={code_from}>
      <AccordionTrigger className="h-[40px]">{code_from}</AccordionTrigger>
      <AccordionContent className="py-1">
        {stepState.map((step) => (
          <StepRow
            key={step.currency_code_from + step.currency_code_to}
            step={step}
            updateSteps={updateSteps}
          />
        ))}
      </AccordionContent>
    </AccordionItem>
  );
};

export default StepGroup;
