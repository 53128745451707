import { useEffect, useState } from "react";
import { Input } from "../../../components/ui/input";
import { TAutocorrCity, TAutocorrSite } from "../../../shared/types/tetris";
import { useCities } from "../../../shared/api/query/use-admin/use-cities";
import { useSites } from "../../../shared/api/query/use-admin/use-sites";
import { Button } from "../../../components/ui/button";
import { createRatesSubscription } from "../../../shared/api/centrifugo/rates";
import { useGlobal } from "../../../shared/api/query/use-admin/use-global";

import { TGlobalDirections } from "../../../shared/types/pair-builder";
import { useRatesStore } from "../../../shared/store/rate-store";
import { showConfirmAlert } from "../components/Alerts";
import { useCreateAutocorr } from "../../../shared/api/query/use-admin/use-autocorr";
import { useQueryClient } from "@tanstack/react-query";
import {
  TetrisBody,
  TetrisContent,
  TetrisFooter,
  TetrisWrapper,
} from "../components/TetrisWrapper";
import CurrencyDirection from "../components/CurrencyDirection/CurrencyDirection";
import Cities from "../components/Cities";
import Sites from "../components/Sites";
import AutocorrList from "./AutocorrList";
import { useCourses } from "../../../shared/api/query/use-course/use-course";

const Autocorr = () => {
  const queryClient = useQueryClient();

  const [selectedVectors, setSelectedVectors] = useState<TGlobalDirections[]>([]);
  const [selectedCities, setSelectedCities] = useState<TAutocorrCity[]>([]);
  const [selectedSites, setSelectedSites] = useState<TAutocorrSite[]>([]);
  const [autocorr_value, setAutocorr] = useState(1);

  const { data: allCities } = useCities();
  const { data: allSites } = useSites();
  const { data: global } = useGlobal();

  const { mutateAsync: createAutocorr } = useCreateAutocorr(queryClient);

  const { rates, setInitRates } = useRatesStore();
  const { data: courses } = useCourses();

  useEffect(() => {
    setInitRates(
      courses?.map((c) => ({
        currency_code_from: c.name.split("-")[0],
        currency_code_to: c.name.split("-")[1],
        course_price: c.value,
        pair_name: c.name,
      })) ?? []
    );
  }, [courses, setInitRates]);

  useEffect(() => {
    createRatesSubscription();
    return () => createRatesSubscription({ unsubscribe: true });
  }, []);

  return (
    <TetrisWrapper>
      <TetrisContent>
        <TetrisBody>
          <CurrencyDirection
            vectors={global?.currency_pairs ?? []}
            selectedVectors={selectedVectors}
            onSelectVectors={setSelectedVectors}
            autocorr_value={autocorr_value}
            rates={rates}
          />

          <Cities
            allCities={allCities ?? []}
            selectedCities={selectedCities}
            onSelectCities={setSelectedCities}
          />

          <Sites
            allSites={allSites ?? []}
            selectedSites={selectedSites}
            onSelectSites={setSelectedSites}
          />
        </TetrisBody>

        <TetrisFooter>
          <div className={`flex gap-5 text-left`}>
            <div>
              <div>Процент</div>
              <Input
                className={`w-[216px] lg:w-[199px] h-[30px] py-4 px-8`}
                type="number"
                value={autocorr_value}
                onChange={(e) =>
                  !Number.isNaN(e.target.value) && setAutocorr(Number(e.target.value))
                }
              />
            </div>
            <div>
              {selectedVectors.length === 1 && selectedVectors[0].rate && (
                <>
                  <div>Курс</div>
                  <Input
                    className={`w-[216px] lg:w-[199px] h-[30px] py-4 px-8`}
                    type="number"
                    onChange={(e) => {
                      const rate = selectedVectors[0].rate;
                      if (rate) setAutocorr(((Number(e.target.value) - rate) / rate) * 100);
                    }}
                  />
                </>
              )}
            </div>
          </div>

          <Button
            className="w-[216px] lg:w-[199px] text-white bg-black"
            disabled={autocorr_value <= 0}
            onClick={() =>
              showConfirmAlert({
                selectedCities,
                selectedVectors,
                selectedSites,
                percent: autocorr_value,
                onAgree: () =>
                  createAutocorr({
                    site_names: selectedSites.map((site) => site.site_name),
                    city_codes: selectedCities.map((city) => city.city_code),
                    vectors: selectedVectors.map((vector) => ({
                      currency_code_from: vector.currency_from.currency_code,
                      currency_code_to: vector.currency_to.currency_code,
                    })),
                    autocorr_value,
                  }),
              })
            }>
            Выставить автокор
          </Button>
        </TetrisFooter>
      </TetrisContent>

      <AutocorrList />
    </TetrisWrapper>
  );
};

export default Autocorr;
