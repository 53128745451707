import Centrifuge from "centrifuge";
import { BASE_URL } from "../../constants";
import api from "../api-instance";
import { useCentrifugeStore } from "../../store/centrifugo-store";

const centrifugo_url = BASE_URL.replace("https", "wss");

export const centrifuge = new Centrifuge(`${centrifugo_url}/centrifugo/connection/websocket`, {
  debug: true,
  refreshAttempts: 1,

  onRefresh: async function () {
    const res = await api.get(`${BASE_URL}/auth/centrifugo/`);
    if (res.status >= 200 && res.status < 300) {
      centrifuge.setToken(res.data.token);
      centrifuge.connect();
    } else {
      unsubscribeAllChannels();
    }
  },

  onRefreshFailed: () => {
    console.error("Refresh failed:");
    unsubscribeAllChannels();
  },
});

centrifuge.on("disconnect", (ctx) => {
  console.warn("Centrifugo disconnected:", ctx);
  useCentrifugeStore.getState().setConnected(false);
});

export const createCentrifugeConnection = async () => {
  const res = await api.get(`${BASE_URL}/auth/centrifugo`);
  if (res.status >= 200 && res.status < 300) {
    centrifuge.setToken(res.data.token);
  }
  centrifuge.connect();
  useCentrifugeStore.getState().setConnected(true);
};

export const unsubscribeAllChannels = () => {
  centrifuge.disconnect();
};
