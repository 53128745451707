import CardListItem from "../../../../shared/ui/CardListItem";
import ListWrapper from "../../../../shared/ui/ListWrapper";
import EditUserRole from "./EditUserRole";
import EditPasswordModal from "./EditPasswordModal";
import { useCallback, useState } from "react";
import CreateUserModal from "./CreateUserModal";
import { useQueryClient } from "@tanstack/react-query";
import {
  useUsers,
  useCreateUser,
  useUpdateUser,
  useDeleteUser,
} from "../../../../shared/api/query/use-admin/use-user";
import { showAlert } from "../../../../app/alert/store";
import OTPModal from "./OTPModal";
import { TEditUser } from "../../../../shared/types/pair-builder";

const UserList = () => {
  const queryClient = useQueryClient();
  const { data: users } = useUsers();

  const { mutateAsync: createUser } = useCreateUser(queryClient);
  const { mutateAsync: updateUser } = useUpdateUser(queryClient);
  const { mutateAsync: deleteUser } = useDeleteUser(queryClient);

  const [isEditPasswordOpen, setIsEditPasswordOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const onChangeOpenEditPasswordHandler = useCallback(() => {
    setIsEditPasswordOpen((open) => !open);
  }, []);

  const onChangeOpenCreateModalHandler = useCallback(() => {
    setIsCreateModalOpen((open) => !open);
  }, []);

  const [user_id, setUserId] = useState<string>("");

  return (
    <ListWrapper>
      <CreateUserModal
        open={isCreateModalOpen}
        onChangeOpen={onChangeOpenCreateModalHandler}
        onCreate={createUser}
      />

      <EditPasswordModal
        open={isEditPasswordOpen}
        onChangeOpen={onChangeOpenEditPasswordHandler}
        onEdit={(values: Omit<TEditUser, "user_id">) => updateUser({ ...values, user_id })}
      />

      <OTPModal />

      {users?.length ? (
        users.map((item) => (
          <CardListItem
            key={item.user_id}
            title={item.user_name}
            onClickEdit={() => {
              setUserId(item.user_id);
              onChangeOpenEditPasswordHandler();
            }}
            onClickDelete={() =>
              showAlert({
                title: `Вы действительно хотите удалить пользователя ${item.user_name} ?`,
                onAgree: () => deleteUser(item.user_id),
              })
            }>
            <EditUserRole
              user={item}
              onEdit={updateUser}
            />
          </CardListItem>
        ))
      ) : (
        <CardListItem title="Список пользователей пуст" />
      )}
    </ListWrapper>
  );
};

export default UserList;
