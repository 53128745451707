import { TAutocorr, TAutocorrCity, TAutocorrSite } from "../../../shared/types/tetris";
import { TGlobalVector } from "../../../shared/types/pair-builder";
import { showAlert } from "../../../app/alert/store";

interface ConfirmAlertProps {
  selectedVectors: TGlobalVector[];
  selectedCities: TAutocorrCity[];
  selectedSites: TAutocorrSite[];
  percent?: number;
}

const ConfirmAlert = ({
  selectedVectors,
  selectedCities,
  selectedSites,
  percent,
}: ConfirmAlertProps) => {
  return (
    <div className="font-light text-sm">
      <div>Направления</div>
      <div className="flex flex-wrap mb-[15px] pt-[10px] pb-[15px] border-b border-[#AAA] max-h-[110px] overflow-y-scroll">
        {selectedVectors?.map((el, index) => (
          <div
            key={index}
            className="font-extrabold mr-5">
            {`${el.currency_from.currency_code}-${el.currency_to.currency_code}`}
          </div>
        ))}
      </div>
      <div>Сайты</div>
      <div className="flex flex-wrap mb-[15px] pt-[10px] pb-[15px] border-b border-[#AAA] max-h-[110px] overflow-y-scroll">
        {selectedSites?.map((el, index) => (
          <div
            key={index}
            className="font-extrabold mr-5">
            {el.site_name}
          </div>
        ))}
      </div>
      <div>Города</div>
      <div className="flex flex-wrap mb-[15px] pt-[10px]  max-h-[110px] overflow-y-scroll">
        {selectedCities?.map((el, index) => (
          <div
            key={index}
            className="font-extrabold mr-5">
            {el.city_name}
          </div>
        ))}
      </div>
      {percent && (
        <div>
          Процент: <span className="font-extrabold">{percent}</span>
        </div>
      )}
    </div>
  );
};

export const showConfirmAlert = ({
  onAgree,
  ...props
}: ConfirmAlertProps & { onAgree?: () => void }) => {
  const { selectedCities, selectedSites, selectedVectors } = props;
  if (selectedCities.length === 0 || selectedSites.length === 0 || selectedVectors.length === 0)
    showAlert({
      title: `Выберите хотя бы один вектор - сайт - город`,
      agreeButtonText: "Понятно",
      showCancelButton: false,
    });
  else
    showAlert({
      agreeButtonText: "Создать",
      children: <ConfirmAlert {...props} />,
      onAgree: onAgree,
    });
};

export const DeleteAlert = ({
  cors,
}: {
  cors: Omit<TAutocorr, "autocorr_value" | "autocorr_is_locked">[];
}) => {
  return (
    <div className="w-full max-h-[70vh] overflow-y-scroll flex flex-col gap-4">
      {cors.map((v) => (
        <div
          key={`${v.site_name} - ${v.city_name} - ${v.currency_code_from}-to-${v.currency_code_to}`}>
          {`${v.site_name} - ${v.city_name} - ${v.currency_code_from}-to-${v.currency_code_to}`}
        </div>
      ))}
    </div>
  );
};
