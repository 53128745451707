import { useMemo, useState } from "react";
import FilterSelector from "../components/CurrencyDirection/FilterSelector";
import SelectAllButton from "../components/SelectAllButton";
import { Option } from "../../../components/ui/multiple-selector";
import SimpleItem from "../components/SimpleItem";
import { TAutocorrSite } from "../../../shared/types/tetris";

interface SitesProps {
  allSites: TAutocorrSite[];
  selectedSites: TAutocorrSite[];
  onSelectSites: (cities: TAutocorrSite[]) => void;
}

const Sites = ({ allSites, selectedSites, onSelectSites }: SitesProps) => {
  const siteOption = allSites.map((c) => ({ value: c.site_name, label: c.site_name }));
  const [filter, setFilter] = useState<Option[]>([]);

  const filteredSites = useMemo(
    () =>
      allSites.filter(
        (item) => filter.length === 0 || filter.some((opt) => opt.value === item.site_name)
      ),
    [allSites, filter]
  );

  const onSelectSite = (site: TAutocorrSite) => {
    const updatedSelectedSites = selectedSites.find((c) => c.site_name === site.site_name)
      ? selectedSites.filter((c) => c.site_name !== site.site_name)
      : [...selectedSites, site];

    onSelectSites(updatedSelectedSites);
  };

  return (
    <div>
      <div className="flex gap-2 pb-1 lg:pb-4">
        <FilterSelector
          options={siteOption}
          placeholder="site"
          value={filter}
          onChange={setFilter}
        />
      </div>

      <SelectAllButton onClickAll={(flag) => onSelectSites(flag ? filteredSites : [])} />

      <div className="grid grid-cols-1 gap-1">
        <div className="flex flex-col gap-1 w-[148px] lg:w-[160px]">
          {filteredSites?.map((el) => (
            <SimpleItem
              {...el}
              key={el.site_name}
              isToggle={!!selectedSites.find((c) => c.site_name === el.site_name)}
              onSelectValue={() => onSelectSite(el)}
              value={el.site_name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sites;
