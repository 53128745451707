import { Card } from "../../../components/ui/card";

interface PropsWithChildrens {
  children?: React.ReactNode | React.ReactNode[] | JSX.Element;
}

export const TetrisWrapper = ({ children }: PropsWithChildrens) => {
  return <div className="flex pb-[40px] gap-4 mx-auto pt-5 flex-col xl:flex-row">{children}</div>;
};

export const TetrisContent = ({ children }: PropsWithChildrens) => {
  return (
    <Card className="table rounded-sm shadow-smooth-out max-w-[706px] px-[10px]">{children}</Card>
  );
};

export const TetrisBody = ({ children }: PropsWithChildrens) => {
  return (
    <div className="frame h-[62vh] square-scroll rounded-sm bg-[#F5F5FD]">
      <div className="flex flex-col gap-6 lg:gap-2 lg:flex-row px-[10px] py-[10px]">
        {children}
      </div>{" "}
    </div>
  );
};

export const TetrisFooter = ({ children }: PropsWithChildrens) => {
  return <div className={`flex flex-col gap-2 p-4 items-center`}>{children}</div>;
};
