import { z } from "zod";
import { AggregatorEnum } from "../../../../shared/types/pair-builder";

export const siteCreateSchema = z
  .object({
    site_name: z.string().min(1, "Поле обязательно"),
    site_url: z.string().min(1, "Поле обязательно"),
    site_db_schema: z.string().min(1, "Поле обязательно"),
    site_scam_checker_enabled: z.boolean(),
    site_bestchange_id: z.union([z.coerce.string(), z.number()]).optional(),
    site_exnode_id: z.union([z.coerce.string(), z.number()]).optional(),
    site_aggregator_name: z.string().min(1, "Поле обязательно"),
    site_update_frequency: z.union([z.coerce.string().min(1, "Поле обязательно"), z.number()]),
  })
  .superRefine((data, ctx) => {
    const isBestchangeEmpty = !data.site_bestchange_id;
    const isExnodeEmpty = !data.site_exnode_id;

    if (data.site_aggregator_name === AggregatorEnum.bestchange && isBestchangeEmpty)
      ctx.addIssue({
        code: "custom",
        path: ["site_bestchange_id"],
        message: "Поле обязательно",
      });

    if (data.site_aggregator_name === AggregatorEnum.exnode && isExnodeEmpty)
      ctx.addIssue({
        code: "custom",
        path: ["site_exnode_id"],
        message: "Поле обязательно",
      });
  });

export const siteDefaultValues = {
  site_name: "",
  site_url: "",
  site_db_schema: "",
  site_scam_checker_enabled: false,
  site_bestchange_id: "",
  site_exnode_id: "",
  site_aggregator_name: AggregatorEnum.bestchange,
  site_update_frequency: "",
};
