import { useState } from "react";
import { Button, ButtonProps } from "../../../components/ui/button";

type SelectAllButtonProps = ButtonProps & {
  onClickAll: (allflag: boolean) => void;
};
const SelectAllButton = ({ onClickAll, ...props }: SelectAllButtonProps) => {
  const [allbuttonflag, setAllButtonFlag] = useState(true);
  return (
    <Button
      className="w-full h-5 mb-3 text-[11px]"
      {...props}
      onClick={() => {
        onClickAll(allbuttonflag);
        setAllButtonFlag((prev) => !prev);
      }}>
      {allbuttonflag ? "+" : "-"}
    </Button>
  );
};

export default SelectAllButton;
