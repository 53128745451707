import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TUserWithOTP } from "../../../../../shared/types/pair-builder";
import { EUserRole } from "../../../../../shared/types/common";

type OTPModalStore = {
  open: boolean;
  onOpenChange: () => void;
  setModalProps: (user: TUserWithOTP) => void;
} & TUserWithOTP;

const initialState = {
  open: false,
  user_id: "",
  user_name: "",
  user_role_name: EUserRole.ADMIN,
};

export const useOTPModalStore = create<OTPModalStore>()(
  persist(
    (set) => ({
      ...initialState,
      onOpenChange: () => set(initialState),
      setModalProps: (user: TUserWithOTP) => set({ open: true, ...user }),
    }),
    {
      name: "otpModalStore",
    }
  )
);

export const showOTP = (user: TUserWithOTP) => {
  const { setModalProps } = useOTPModalStore.getState();
  setModalProps(user);
};
