import { Button } from "../../../../components/ui/button";
import { Plus } from "lucide-react";
import { Input } from "../../../../components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import { z } from "zod";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "../../../../components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { TSite, TCreateSite, AggregatorEnum } from "../../../../shared/types/pair-builder";
import { siteCreateSchema, siteDefaultValues } from "./validation";
import { ToggleGroup, ToggleGroupItem } from "../../../../components/ui/toggle-group";
import { Switch } from "../../../../components/ui/switch";

interface CreateModalProps {
  site?: TSite;
  open: boolean;
  onChangeOpen: () => void;
  onEdit: (payload: TSite) => void;
  onCreate: (payload: TCreateSite) => void;
}

const CreateModal = ({ open, onChangeOpen, site, onEdit, onCreate }: CreateModalProps) => {
  const editMode = !!site;

  const siteCreateForm = useForm<z.infer<typeof siteCreateSchema>>({
    resolver: zodResolver(siteCreateSchema),
    values: editMode ? site : siteDefaultValues,
  });

  const onChangeOpenModalHandler = () => {
    if (open) siteCreateForm.reset(siteDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof siteCreateSchema>) => {
    const site_id_remover =
      values.site_aggregator_name === AggregatorEnum.exnode
        ? { site_bestchange_id: undefined }
        : { site_exnode_id: undefined };

    const valuesWithNumber = {
      ...values,
      site_bestchange_id: Number(values.site_bestchange_id),
      site_exnode_id: Number(values.site_exnode_id),
      ...site_id_remover,
      site_update_frequency: Number(values.site_update_frequency),
    };

    if (editMode) onEdit({ ...valuesWithNumber, site_id: site.site_id } as TSite);
    else onCreate(valuesWithNumber as TCreateSite);
    onChangeOpenModalHandler();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogTrigger asChild>
        <Button
          className="self-end"
          variant="outline"
          size="icon">
          <Plus />
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{editMode ? "Редактировать обменник" : "Новый обменник"}</DialogTitle>
        </DialogHeader>

        <Form {...siteCreateForm}>
          <form
            onSubmit={siteCreateForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-3">
            <FormField
              control={siteCreateForm.control}
              name="site_aggregator_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl className="flex justify-end">
                    <ToggleGroup
                      type="single"
                      value={field.value}
                      onValueChange={field.onChange}>
                      {Object.values(AggregatorEnum).map((type) => (
                        <ToggleGroupItem
                          value={type}
                          className="data-[state=on]:bg-black data-[state=on]:text-white">
                          {type}
                        </ToggleGroupItem>
                      ))}
                    </ToggleGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={siteCreateForm.control}
              name="site_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Название*</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={editMode}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={siteCreateForm.control}
              name="site_url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>URL*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={siteCreateForm.control}
              name="site_db_schema"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>DB schema*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {siteCreateForm.getValues().site_aggregator_name === AggregatorEnum.bestchange ? (
              <FormField
                control={siteCreateForm.control}
                name="site_bestchange_id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>ID bestchange*</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : (
              <FormField
                control={siteCreateForm.control}
                name="site_exnode_id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>ID exnode*</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={siteCreateForm.control}
              name="site_update_frequency"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Частота обновлений*</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={siteCreateForm.control}
              name="site_scam_checker_enabled"
              render={({ field }) => (
                <FormItem className="flex gap-2 items-center">
                  <FormLabel className="pt-1">Scam checker enabled</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="submit">{editMode ? "ИЗМЕНИТЬ ОБМЕННИК" : "ДОБАВИТЬ ОБМЕННИК"}</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
