import { useCallback, useState } from "react";
import CardListItem from "../../../../shared/ui/CardListItem";
import ListWrapper from "../../../../shared/ui/ListWrapper";
import CreateModal from "./CreateModal";
import { TCountry } from "../../../../shared/types/pair-builder";
import {
  useCountries,
  useCreateCountry,
  useDeleteCountry,
  useUpdateCountry,
} from "../../../../shared/api/query/use-admin/use-countries";
import { useQueryClient } from "@tanstack/react-query";
import { showAlert } from "../../../../app/alert/store";

const CountryList = () => {
  const queryClient = useQueryClient();
  const { data: countries } = useCountries();

  const { mutateAsync: createCountry } = useCreateCountry(queryClient);
  const { mutateAsync: updateCountry } = useUpdateCountry(queryClient);
  const { mutateAsync: deleteCountry } = useDeleteCountry(queryClient);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCountry, setEditCountry] = useState<TCountry | undefined>(undefined);

  const onChangeOpenModalHandler = useCallback(() => {
    if (isEditModalOpen) setEditCountry(undefined);
    setIsEditModalOpen((open) => !open);
  }, [isEditModalOpen]);

  const onClickEditCountryHandler = (country: TCountry) => {
    setEditCountry(country);
    onChangeOpenModalHandler();
  };

  return (
    <ListWrapper>
      <CreateModal
        open={isEditModalOpen}
        onChangeOpen={onChangeOpenModalHandler}
        country={editCountry}
        onEdit={(payload) => updateCountry(payload)}
        onCreate={(payload) => createCountry(payload)}
      />

      {countries?.length ? (
        countries.map((item) => (
          <CardListItem
            key={item.country_code}
            title={item.country_name}
            onClickEdit={() => onClickEditCountryHandler(item)}
            onClickDelete={() =>
              showAlert({
                title: `Вы действительно хотите удалить страну ${item.country_name} ?`,
                onAgree: () => deleteCountry(item.country_code),
              })
            }
          />
        ))
      ) : (
        <CardListItem title="Список стран пуст" />
      )}
    </ListWrapper>
  );
};

export default CountryList;
