import { create } from "zustand";

interface CentrifugeState {
  isConnected: boolean;
  setConnected: (state: boolean) => void;
}

export const useCentrifugeStore = create<CentrifugeState>((set) => ({
  isConnected: false,
  setConnected: (state) => set({ isConnected: state }),
}));
