import { create } from "zustand";

export enum ViewEnum {
  user = "user",
  currency = "currency",
  city = "city",
  country = "country",
  site = "site",
}

interface ViewStore {
  view: ViewEnum;
  setView: (newView: ViewEnum) => void;
}

export const useView = create<ViewStore>()((set) => ({
  view: ViewEnum.user,
  setView: (newView) => set({ view: newView }),
}));
