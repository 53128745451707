import { useEffect, useMemo } from "react";
import { useSteps } from "../../shared/api/query/use-admin/use-step";
import { Card } from "../../components/ui/card";
import { StepItem, TUpdateStep } from "../../shared/types/pair-builder";
import StepGroup from "./StepGroup";
import ListWrapper from "../../shared/ui/ListWrapper";
import { useQueryClient } from "@tanstack/react-query";
import { Accordion } from "../../components/ui/accordion";

export interface CityStep {
  site_name: string;
  updatedSteps: TUpdateStep[];
  setUpdatedSteps: (steps: TUpdateStep[]) => void;
}

const CityStep = ({ site_name, updatedSteps, setUpdatedSteps }: CityStep) => {
  const queryClient = useQueryClient();
  const { data: steps } = useSteps(site_name);

  const code_from_group = useMemo(
    () => [...new Set(steps?.map((el) => el.currency_code_from))],
    [steps]
  );

  const updateStepsHandler = (step: StepItem) => {
    setUpdatedSteps(
      updatedSteps.find(
        (item) =>
          item.currency_code_from === step.currency_code_from &&
          item.currency_code_to === step.currency_code_to
      )
        ? updatedSteps.map((item) => {
            if (
              item.currency_code_from === step.currency_code_from &&
              item.currency_code_to === step.currency_code_to
            )
              return { ...item, ...step };
            else return item;
          })
        : [...updatedSteps, step as TUpdateStep]
    );
  };

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["get-steps"] });
  }, [site_name]);

  return (
    <>
      <ListWrapper className="w-fit">
        <Card>
          <div className="grid grid-cols-[2fr_1fr_1fr_1fr] h-[40px] px-8 items-center rounded-t-xl text-white  bg-[rgb(40,44,52)]">
            <div className="text-center font-bold">ВЕКТОР</div>
            <div className="text-center font-bold">MIN ШАГ</div>
            <div className="text-center font-bold">MAX ШАГ</div>
            <div className="text-center font-bold">ШАГ</div>
          </div>

          <Accordion
            type="multiple"
            className="px-8 py-5 pt-3">
            {code_from_group.map((group) => (
              <StepGroup
                key={site_name + group}
                code_from={group}
                steps={steps ?? []}
                updateSteps={updateStepsHandler}
              />
            ))}
          </Accordion>
        </Card>
      </ListWrapper>
    </>
  );
};

export default CityStep;
