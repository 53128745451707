import { LockIcon, LockOpenIcon } from "lucide-react";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Button } from "../../../../components/ui/button";
import { cn } from "../../../../shared/lib/utils";
import { TAutocorr, TAutocorrWithDirection } from "../../../../shared/types/tetris";
import { useUpdateAutocorrLocked } from "../../../../shared/api/query/use-admin/use-autocorr";
import { useQueryClient } from "@tanstack/react-query";
import ListToggle from "../../components/ListToggle";

interface AutocorrItemProps {
  autocorr: TAutocorrWithDirection;
  pickOptions: Partial<TAutocorrWithDirection>;
  setPick: (options: Partial<TAutocorrWithDirection>) => void;
  selected: (TAutocorr & { id: string })[];
  setSelected: (selected: (TAutocorr & { id: string })[]) => void;
}

const AutocorrItem = ({
  autocorr,
  pickOptions,
  setPick,
  selected,
  setSelected,
}: AutocorrItemProps) => {
  const queryClient = useQueryClient();
  const { mutateAsync: update } = useUpdateAutocorrLocked(queryClient);

  const { autocorr_is_locked: locked, site_name, direction, city_name, autocorr_value } = autocorr;

  const options = { site_name, direction, city_name, autocorr_value };

  const onSelectItem = () => {
    const id =
      autocorr.site_name + autocorr.direction + autocorr.city_name + autocorr.autocorr_value;

    setSelected(
      selected.find((a) => a.id === id)
        ? selected.filter((i) => i.id !== id)
        : [...selected, { ...autocorr, id: id }]
    );
  };

  return (
    <div
      className={cn(
        "flex flex-row flex-wrap gap-1 text-xs rounded-lg p-1",
        locked && "bg-[hsl(var(--input))]"
      )}>
      <Button
        variant="outline"
        onClick={() => update({ ...autocorr, autocorr_is_locked: !locked })}
        className={cn(
          "rounded-full h-[20px] w-[20px] p-[3px] ",
          locked &&
            "bg-[var(--autocorr-theme)] text-white hover:bg-[var(--autocorr-theme)] hover:text-white"
        )}>
        {locked ? <LockIcon /> : <LockOpenIcon />}
      </Button>

      {!locked && (
        <Checkbox
          className="rounded-full h-[20px] w-[20px] border-[hsl(var(--input))] bg-white data-[state=checked]:bg-[var(--autocorr-theme)]"
          onCheckedChange={onSelectItem}
        />
      )}

      {Object.entries(options).map(([key, value]) => {
        const isPressed = pickOptions[key as keyof TAutocorrWithDirection] === value;
        return (
          <ListToggle
            key={value}
            value={value.toString()}
            pressed={isPressed}
            onPressedChange={() =>
              setPick({ ...pickOptions, [key]: isPressed ? undefined : value })
            }
          />
        );
      })}
    </div>
  );
};

export default AutocorrItem;
