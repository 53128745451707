import { useBaseQuery } from "../base-query";
import adminService from "../../services/admin-service";
import { TStep, TUpdateStep } from "../../../types/pair-builder";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";

export const useSteps = (site_name: string) =>
  useBaseQuery<null, TStep[]>(["get-steps"], () => adminService.getSteps(site_name));

export const useUpdateSteps = (queryClient: QueryClient) =>
  useBaseMutation<TUpdateStep[], TStep[]>(
    (payload: TUpdateStep[]) => adminService.updateSteps(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-steps"] })
  );
