import { currencyConfig } from "./types";

export const CurrencyIcons = ({ type }: { type: string }) => {
  const config = currencyConfig[type] || {};
  const {
    d = "",
    d2 = "",
    d3 = "",
    d4 = "",
    fill = "",
    bg = "inherit",
    viewBox = "0 0 24 24",
  } = config;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: `${bg}`,
        width: bg === "inherit" ? "30px" : "19px",
        height: bg === "inherit" ? "20px" : "19px",
        borderRadius: "50%",
        margin: "2px 0 0 0",
      }}>
      {fill === "#FFF" ? (
        <div
          style={{
            color: "#FFF",
            fontSize:
              type === "CASHAED" || type === "POSTBRUB" || type === "TCSBRUB" || type === "SBERRUB"
                ? "11px"
                : "16px",
            fontWeight: "700",
          }}>
          {d}
        </div>
      ) : fill === "im icon" ? (
        <img
          style={{ position: "relative", bottom: "1px", maxHeight: "30px", maxWidth: "30px" }}
          src={d}
          alt="trx"
        />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox}
          width={bg === "inherit" ? "24" : "30"}
          height={bg === "inherit" ? "24" : "30"}>
          <path
            d={d}
            fill={type === "USDC" ? "#1570EF" : fill}
          />
          {d2.length > 0 && (
            <path
              d={d2}
              fill={fill}
            />
          )}
          {d3.length > 0 && (
            <path
              d={d3}
              fill={fill}
            />
          )}
          {d4.length > 0 && (
            <path
              d={d4}
              fill={fill}
            />
          )}
        </svg>
      )}
    </div>
  );
};
