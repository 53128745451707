import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { z } from "zod";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
} from "../../../../components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { userEditSchema, userDefaultValues } from "./validation";
import { TEditUser } from "../../../../shared/types/pair-builder";

interface EditModalProps {
  open: boolean;
  onChangeOpen: () => void;
  onEdit?: (values: Omit<TEditUser, "user_id">) => void;
}

const EditPasswordModal = ({ open, onChangeOpen, onEdit }: EditModalProps) => {
  const userPasswordForm = useForm<z.infer<typeof userEditSchema>>({
    resolver: zodResolver(userEditSchema),
  });

  const onChangeOpenModalHandler = () => {
    if (open) userPasswordForm.reset(userDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof userEditSchema>) => {
    onEdit?.(values);
    onChangeOpenModalHandler();
  };

  const onUpdateOTP = () => {
    onEdit?.({ reset_user_otp: true });
    onChangeOpenModalHandler();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{"Введите новый пароль"}</DialogTitle>
        </DialogHeader>

        <Form {...userPasswordForm}>
          <form
            onSubmit={userPasswordForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-8">
            <FormField
              control={userPasswordForm.control}
              name="user_password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder="от 12 символов"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="flex sm:flex-col flex-col gap-2">
              <Button
                type="submit"
                disabled={userPasswordForm.getValues().user_password === ""}>
                {"Обновить пароль"}
              </Button>
              <div className="text-center">или</div>
              <Button
                onClick={onUpdateOTP}
                className="!m-0">
                {"Обновить OTP"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditPasswordModal;
