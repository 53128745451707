import { Button } from "../../../../../components/ui/button";
import { Plus } from "lucide-react";
import { Input } from "../../../../../components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { z } from "zod";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "../../../../../components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { baseCurrencyCreateSchema, baseCurrencyDefaultValues } from "./validation";
import { TBaseCurrencyCode } from "../../../../../shared/types/pair-builder";

interface CreateModalProps {
  open: boolean;
  onChangeOpen: () => void;
  onCreate: (payload: TBaseCurrencyCode) => void;
}

const CreateModal = ({ open, onChangeOpen, onCreate }: CreateModalProps) => {
  const currencyCreateForm = useForm<z.infer<typeof baseCurrencyCreateSchema>>({
    resolver: zodResolver(baseCurrencyCreateSchema),
    values: baseCurrencyDefaultValues,
  });

  const onChangeOpenModalHandler = () => {
    if (open) currencyCreateForm.reset(baseCurrencyDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof baseCurrencyCreateSchema>) => {
    onCreate(values);

    onChangeOpenModalHandler();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogTrigger asChild>
        <Button
          className="self-end"
          variant="outline"
          size="icon">
          <Plus />
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{"Новая базовая валюта"}</DialogTitle>
        </DialogHeader>

        <Form {...currencyCreateForm}>
          <form
            onSubmit={currencyCreateForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-4">
            <FormField
              control={currencyCreateForm.control}
              name="base_currency_code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Base code</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      value={field.value === null ? undefined : field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="submit">{"ДОБАВИТЬ ВАЛЮТУ"}</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
