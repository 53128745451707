export enum EUserRole {
  ADMIN = "USER_ROLE_ADMIN",
  MINI_ADMIN = "USER_ROLE_MINI_ADMIN",
  ANALYTIC = "USER_ROLE_ANALYTIC",
  CONTROLLER = "USER_ROLE_CONTROLLER",
  CONTROLLER_ADMIN = "USER_ROLE_CONTROLLER_ADMIN",
  COURSER_RECRUIT = "USER_ROLE_COURSER_RECRUIT",
  COURSER = "USER_ROLE_COURSER",
  COURSER_ADMIN = "USER_ROLE_COURSER_ADMIN",
  COURSER_SUPER_ADMIN = "USER_ROLE_COURSER_SUPER_ADMIN",
  SUPPORT = "USER_ROLE_SUPPORT",
  MERCH = "USER_ROLE_MERCH",
  SECURITY_CONTROL = "USER_ROLE_SECURITY_CONTROL",
  FULL_CONTROL = "USER_ROLE_FULL_CONTROL",
}

export type TUser = {
  user_id: string;
  user_name: string;
  user_role_name: EUserRole;
};

export enum CurrencyTypeEnum {
  crypto = "CRYPTO",
  cash = "CASH",
  card = "CARD",
}
