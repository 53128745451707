import { showAlert } from "../../../../app/alert/store";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { EUserRole, TUser } from "../../../../shared/types/common";
import { TEditUser } from "../../../../shared/types/pair-builder";

interface EditUserRoleProps {
  user: TUser;
  onEdit: (payload: TEditUser) => void;
}

const EditUserRole = ({ user, onEdit }: EditUserRoleProps) => {
  return (
    <Select
      value={user.user_role_name}
      onValueChange={(value) =>
        showAlert({
          title: `Вы действительно хотите назначить пользователю ${user.user_name} роль ${value}?`,
          onAgree: () => onEdit({ ...user, user_role_name: value as EUserRole }),
        })
      }
      defaultValue={user.user_role_name}>
      <SelectTrigger className="w-[120px]">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {Object.keys(EUserRole).map((item) => (
            <SelectItem
              key={item}
              value={EUserRole[item as keyof typeof EUserRole]}>
              {item}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default EditUserRole;
