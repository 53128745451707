import { CurrencyTypeEnum, EUserRole, TUser } from "./common";

export interface TCity {
  city_id: string;
  city_name: string;
  city_code: string;
  city_is_region: boolean;
  country_code: string;
  city_bestchange_id?: number;
}

export type TCreateCity = Omit<TCity, "city_id">;

export interface TCountry {
  country_id: number;
  country_name: string;
  country_code: string;
}

export type TCreateCountry = Omit<TCountry, "country_id">;

export interface CurrencyCountry {
  country_name: string;
  country_code: string;
}

export interface TCurrency {
  currency_id: string;
  currency_name: string;
  currency_code: string;
  currency_type: CurrencyTypeEnum;
  currency_exnode_id: number;
  currency_bestchange_id: number;
  currency_bestchange_url: string;
  currency_base_code: string;
  countries?: CurrencyCountry[];
}

export interface TBaseCurrencyCode {
  base_currency_code: string;
}

export type TBaseCurrency = {
  base_currency_id: number;
} & TBaseCurrencyCode;

export type TCreateCurrency = Omit<TCurrency, "currency_id" | "currency_base_code" | "countries">;

export enum AggregatorEnum {
  bestchange = "Bestchange",
  exnode = "Exnode",
}

export interface TSite {
  site_id: number;
  site_name: string;
  site_url: string;
  site_db_schema: string;
  site_scam_checker_enabled: boolean;
  site_bestchange_id?: number;
  site_exnode_id?: number;
  site_aggregator_name: AggregatorEnum;
  site_update_frequency: number;
}

export type TCreateSite = Omit<TSite, "site_id">;

export type NestedObjects<T> = {
  [key: string]: T;
};

interface TGlobalCurrency {
  currency_code: string;
  currency_base_code?: string;
  currency_type: CurrencyTypeEnum;
}

export interface TGlobalVector {
  currency_from: TGlobalCurrency;
  currency_to: TGlobalCurrency;
}

export type TGlobalDirections = TGlobalVector & {
  rate?: number;
};

export interface TGlobal {
  currency_pairs: TGlobalVector[];
  sites: Pick<TSite, "site_id" | "site_name">[];
}

export type TCreateUser = Omit<TUser, "user_id"> & {
  user_password: string;
};

export type TUserWithOTP = TUser & {
  user_otp_key?: string;
  user_password?: string;
};

export type TEditUser = Pick<TUser, "user_id"> & {
  user_password?: string;
  user_role_name?: EUserRole;
  reset_user_otp?: boolean;
};

export interface TStep {
  step_id: number;
  currency_code_from: string;
  currency_code_to: string;
  site_name: string;
  step_min_value: number;
  step_max_value: number;
  step_value: number;
}

export type TUpdateStep = Omit<TStep, "step_id" | "step_value">;

export type StepItem = Partial<TStep> & Pick<TStep, "currency_code_from" | "currency_code_to">;
