import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toastRequestResults } from "./toasts";

export function useBaseMutationWithoutPayload<R>(
  request: () => Promise<AxiosResponse<R, string>>,
  onSuccess?: () => void
) {
  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: async () => {
      try {
        const response = await request();
        toastRequestResults(response.status);

        return response.data;
      } catch (e) {
        throw new Error(e as string);
      }
    },
    onSuccess,
  });

  return {
    mutateAsync,
    isPending,
    error,
  };
}
