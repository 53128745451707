import { AxiosResponse } from "axios";
import { BASE_URL } from "../../constants";

import { TLogin } from "../../types/auth";
import { TUser } from "../../types/common";
import api, { tokenRefreshClient } from "../api-instance";
import { ENDPOINT_AUTH } from "../endpoints";

export default class authService {
  static async login(data: TLogin): Promise<AxiosResponse<TUser>> {
    return api.post(`${BASE_URL}/${ENDPOINT_AUTH}/login/`, data);
  }
  static async logout() {
    return api.post(`${BASE_URL}/${ENDPOINT_AUTH}/logout/`);
  }

  static async refresh() {
    return tokenRefreshClient.patch(`${BASE_URL}/${ENDPOINT_AUTH}/refresh/`);
  }
}
