import * as TogglePrimitive from "@radix-ui/react-toggle";
import { Toggle } from "../../../components/ui/toggle";

type ListToggleProps = {
  value: string;
} & React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root>;

const ListToggle = ({ value, ...props }: ListToggleProps) => {
  return (
    <Toggle
      className="h-[20px] p-1 min-w-1 text-xs data-[state=on]:bg-[var(--autocorr-theme)] data-[state=on]:text-white"
      {...props}>
      {value}
    </Toggle>
  );
};

export default ListToggle;
