import { Button } from "../../../../components/ui/button";
import { Plus } from "lucide-react";
import { cityCreateSchema, cityDefaultValues } from "./validation";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  DialogHeader,
  DialogFooter,
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Switch } from "../../../../components/ui/switch";
import { TCity, TCreateCity } from "../../../../shared/types/pair-builder";
import { useCountries } from "../../../../shared/api/query/use-admin/use-countries";

interface CreateModalProps {
  city?: TCity;
  open: boolean;
  onChangeOpen: () => void;
  onEdit: (payload: TCity) => void;
  onCreate: (payload: TCreateCity) => void;
}

const CreateModal = ({ open, onChangeOpen, city, onEdit, onCreate }: CreateModalProps) => {
  const editMode = !!city;
  const { data: countries } = useCountries();

  const cityCreateForm = useForm<z.infer<typeof cityCreateSchema>>({
    resolver: zodResolver(cityCreateSchema),
    values: editMode ? city : cityDefaultValues,
  });

  const onChangeOpenModalHandler = () => {
    if (open) cityCreateForm.reset(cityDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof cityCreateSchema>) => {
    const valuesWithNumber = {
      ...values,
      city_bestchange_id: Number(values.city_bestchange_id),
    };

    if (editMode) onEdit({ ...valuesWithNumber, city_id: city.city_id });
    else onCreate(valuesWithNumber);

    onChangeOpenModalHandler();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogTrigger asChild>
        <Button
          className="self-end"
          variant="outline"
          size="icon">
          <Plus />
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{editMode ? "Редактировать город" : "Новый город"}</DialogTitle>
        </DialogHeader>

        <Form {...cityCreateForm}>
          <form
            onSubmit={cityCreateForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-4">
            <FormField
              control={cityCreateForm.control}
              name="city_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Город*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={cityCreateForm.control}
              name="city_code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Код города*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={cityCreateForm.control}
              name="country_code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Страна*</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {countries?.map((country) => (
                        <SelectItem value={country.country_code}>{country.country_name}</SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={cityCreateForm.control}
              name="city_bestchange_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>ID города на bestchange</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={cityCreateForm.control}
              name="city_is_region"
              render={({ field }) => (
                <FormItem className="flex gap-2 items-center">
                  <FormLabel className="pt-1">Регион</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="submit">{editMode ? "ИЗМЕНИТЬ ГОРОД" : "ДОБАВИТЬ ГОРОД"}</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
