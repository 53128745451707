import { useMemo, useState } from "react";
import { RateItem } from "../../../../shared/store/rate-store";
import { TGlobalDirections, TGlobalVector } from "../../../../shared/types/pair-builder";
import SelectAllButton from "../SelectAllButton";
import CurrencyItem from "./CurrencyItem";
import FilterSelector from "./FilterSelector";
import { Option } from "../../../../components/ui/multiple-selector";

interface CurrencyDirectionProps {
  vectors: TGlobalDirections[];
  selectedVectors: TGlobalDirections[];
  onSelectVectors: (vectors: TGlobalDirections[]) => void;
  autocorr_value?: number;
  rates?: RateItem[];
}

const CurrencyDirection = ({
  vectors,
  selectedVectors,
  onSelectVectors,
  autocorr_value,
  rates,
}: CurrencyDirectionProps) => {
  const currencyDirFromOptions = Array.from(
    new Set(vectors.map((i) => i.currency_from.currency_code))
  ).map((value) => ({ value, label: value }));

  const currencyDirToOptions = Array.from(
    new Set(vectors.map((i) => i.currency_to.currency_code))
  ).map((value) => ({ value, label: value }));

  const [filter, setFilter] = useState<{
    currency_code_from?: Option[];
    currency_code_to?: Option[];
  }>({});

  const filteredVectors = useMemo(() => {
    return vectors.filter((item) => {
      const fromMatches =
        !filter.currency_code_from ||
        filter.currency_code_from.length === 0 ||
        filter.currency_code_from.some((opt) => opt.value === item.currency_from.currency_code);

      const toMatches =
        !filter.currency_code_to ||
        filter.currency_code_to.length === 0 ||
        filter.currency_code_to.some((opt) => opt.value === item.currency_to.currency_code);

      return fromMatches && toMatches;
    });
  }, [vectors, filter]);

  const onSelectVector = (vector: TGlobalVector) => {
    const exists = selectedVectors.some(
      (v) =>
        v.currency_from.currency_code === vector.currency_from.currency_code &&
        v.currency_to.currency_code === vector.currency_to.currency_code
    );
    const updatedSelectedVectors = exists
      ? selectedVectors.filter(
          (v) =>
            v.currency_from.currency_code !== vector.currency_from.currency_code ||
            v.currency_to.currency_code !== vector.currency_to.currency_code
        )
      : [...selectedVectors, vector];

    onSelectVectors(updatedSelectedVectors);
  };

  const baseCodeMap = useMemo(() => {
    const map = new Map<string, string>();

    vectors.forEach(({ currency_from, currency_to }) => {
      const { currency_code: from, currency_base_code: baseFrom } = currency_from;
      const { currency_code: to, currency_base_code: baseTo } = currency_to;

      map.set(from, baseFrom ? baseFrom : from);
      map.set(to, baseTo ? baseTo : to);
    });

    return map;
  }, [vectors]);

  return (
    <div>
      <div className="flex gap-2 pb-1 lg:pb-4">
        <FilterSelector
          options={currencyDirFromOptions}
          placeholder="from"
          value={filter.currency_code_from}
          onChange={(options) =>
            setFilter((filter) => ({ ...filter, currency_code_from: options }))
          }
        />
        <FilterSelector
          options={currencyDirToOptions}
          placeholder="to"
          value={filter.currency_code_to}
          onChange={(options) => setFilter((filter) => ({ ...filter, currency_code_to: options }))}
        />
      </div>

      <SelectAllButton onClickAll={(flag) => onSelectVectors(flag ? filteredVectors : [])} />

      <div className="grid grid-cols-2 gap-1">
        <div className="flex flex-col gap-1 w-[148px] lg:w-[160px]">
          {filteredVectors?.slice(0, Math.ceil(filteredVectors.length / 2)).map((el) => (
            <CurrencyItem
              key={`${el.currency_from.currency_code}-${el.currency_to.currency_code}`}
              vector={el}
              rates={rates}
              isToggle={
                !!selectedVectors?.find(
                  (sv) => sv.currency_from === el.currency_from && sv.currency_to === el.currency_to
                )
              }
              autocorr_value={autocorr_value}
              onSelectVector={onSelectVector}
              baseCodeMap={baseCodeMap}
            />
          ))}
        </div>
        <div className="flex flex-col gap-1 w-[148px] lg:w-[160px]">
          {filteredVectors?.slice(Math.ceil(filteredVectors.length / 2)).map((el) => (
            <CurrencyItem
              key={`${el.currency_from.currency_code}-${el.currency_to.currency_code}`}
              vector={el}
              rates={rates}
              isToggle={
                !!selectedVectors?.find(
                  (sv) => sv.currency_from === el.currency_from && sv.currency_to === el.currency_to
                )
              }
              autocorr_value={autocorr_value}
              onSelectVector={onSelectVector}
              baseCodeMap={baseCodeMap}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CurrencyDirection;
