const GATEWAY = "gateway/v1";
const PAIR_BUILDER = "pair_builder/v1";

// currencies
export const ENDPOINT_CURRENCY = `${GATEWAY}/${PAIR_BUILDER}/currency`;

// base currencies
export const ENDPOINT_BASE_CURRENCY = `${GATEWAY}/${PAIR_BUILDER}/base_currency`;

// cities
export const ENDPOINT_CITY = `${GATEWAY}/${PAIR_BUILDER}/city`;

// countries
export const ENDPOINT_COUNTRY = `${GATEWAY}/${PAIR_BUILDER}/country`;

//site
export const ENDPOINT_SITE = `${GATEWAY}/${PAIR_BUILDER}/site`;

//autocorr
export const ENDPOINT_AUTOCORR = `${GATEWAY}/${PAIR_BUILDER}/autocorr`;

//blacklist
export const ENDPOINT_BLACKLIST = `${GATEWAY}/${PAIR_BUILDER}/blacklist`;

//global
export const ENDPOINT_GLOBAL = `${GATEWAY}/${PAIR_BUILDER}/global`;

//step
export const ENDPOINT_STEP = `${GATEWAY}/${PAIR_BUILDER}/step`;

//user
export const ENDPOINT_USER = `${GATEWAY}/user`;

//auth
export const ENDPOINT_AUTH = `${GATEWAY}/auth`;

//best_analytics
export const ENDPOINT_BEST_ANALITICS = `${GATEWAY}/best_analytics/v1`;

//course
export const ENDPOINT_COURSE = `${GATEWAY}/course_api/v1/course`;
