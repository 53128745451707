import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toastRequestResults } from "./toasts";

export function useBaseMutation<P, R>(
  request: (payload: P) => Promise<AxiosResponse<R, string>>,
  onSuccess?: (data: R | null, variables?: P) => void
) {
  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: async (payload: P) => {
      try {
        const response = await request(payload);
        if (!response) return null;

        toastRequestResults(response.status);

        return response.data;
      } catch (e) {
        throw new Error(e as string);
      }
    },
    onSuccess,
  });

  return {
    mutateAsync,
    isPending,
    error,
  };
}
